// ======== neue regular =============
@font-face {
    font-family: 'NeueHaasDisplay-Black';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Black.svg') format('svg'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Black.ttf') format('truetype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NeueHaasDisplayBlack';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.eot');
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// ======== neue medium =============
@font-face {
    font-family: 'NeueHaasDisplay-Mediu';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Mediu.svg') format('svg'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Mediu.ttf') format('truetype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Mediu.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NeueHaasDisplayMediu';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayMediu.eot');
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayMediu.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayMediu.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


// ======== neue bold =============
@font-face {
    font-family: 'NeueHaasDisplay-Bold';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Bold.svg') format('svg'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Bold.ttf') format('truetype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NeueHaasDisplayBold';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBold.eot');
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBold.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// ======== neue roman =============
@font-face {
    font-family: 'NeueHaasDisplay-Roman';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Roman.svg') format('svg'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Roman.ttf') format('truetype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplay-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NeueHaasDisplayRoman';
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayRoman.eot');
    src: url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayRoman.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/NeueHaasDisplay/NeueHaasDisplayRoman.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
$ff-primary:'NeueHaasDisplay-Bold', sans-serif;
$ff-secondary:'NeueHaasDisplay-Roman', sans-serif;
$ff-neue-medium:'NeueHaasDisplay-Mediu' sans-serif;
$ff-neue-black:'NeueHaasDisplay-Black', sans-serif;