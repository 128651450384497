@import '../../Abstract/function';
@import '../../Abstract/breakpoints';
@import '../../Abstract/mixins';
@import '../../Abstract/colors'; 
@import '../../Abstract/font'; 

// ======================= marque-banner =============================
.marque-container{
    position: relative;
    width:100%;
}

.marque-banner {
    position: relative;
    @include flex-center-vertical;
    overflow: hidden;
    transition: animation 250ms linear;
}

.marque-banner__track {
    display: flex;
    align-items: center;
    gap: rem(48);
    height:3em;
    white-space: nowrap;
    animation: scan 15s linear infinite;

    @include breakpoint-md {
        animation-duration: 30s;
    }
}

.marque-item {
    display: block;
    flex-shrink: 0;
    padding-inline: rem(8);
    font-size:rem(22);
    font-family: $ff-secondary;
    text-transform: capitalize;
    color: $clr-white;
}

@keyframes scan {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%);
    }
}


.marque-banner {
    background: $clr-secondary;
}
