@import './function';

// using em based breakpoint to improve the browser support (safari)
$sm:em(576);
$md:em(768);
$lg:em(992);
$xl:em(1200);
$xxl:em(1600);

// ======== mobile device =============
@mixin breakpoint-sm{
    @media only screen and (min-width:$sm){
        @content;
    }
}

// ========== tablet device ============
@mixin breakpoint-md{
  @media only screen and (min-width: $md){
    @content;
  }
}

// ========= laptop device =============== 
@mixin breakpoint-lg {
  @media only screen and (min-width: $lg){
    @content;
  }
}

// ========== desktop device ==========
@mixin breakpoint-xl {
  @media only screen and (min-width: $xl){
    @content;
  }
}

// =========== large desktop device =====
@mixin breakpoint-xxl{
  @media only screen and (min-width:$xxl){
    @content;
  }
}