@import '../Abstract/colors';
@import '../Abstract/function';
@import '../Abstract/breakpoints';
@import '../Abstract/mixins';

.sidemenu{
    width:60%;
    height:100vh;
    background-color:$clr-black;
    position:fixed;
    right:0;
    bottom:0;
    top:0px;
    transition-delay: .1ms;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform:translate3d(100%, 0, 0);
    z-index:995;

    &--is-open{
        transform:translate3d(0, 0, 0);
    }

    @include breakpoint-md{
        width:40%;
    }

    @include breakpoint-lg{
        width:25%;
    }

    &__nav-title,
    &__nav-list,
    &__nav-item,
    &__link{
        color:$clr-white;
    }

    &__nav-list{
        display:grid;
        gap:rem(6);
    }

    &__nav-item{
        padding:rem(6) rem(8);
        border-radius:rem(32);

        &:hover{
            background-color:lighten($clr-primary, 10%);
        }
    }

    &__link{
        display:flex;
        align-items:center;
        gap:10px;
    }

    svg{
        outline:$clr-white;
        fill:$clr-white;
    }

    hr{
        color:#d3d3d3;
    }

    &__social-list{
        gap:rem(10);

        @include breakpoint-md{
            gap:rem(16);
        }

        @include breakpoint-lg{
            gap:rem(24);
        }
    }

    &__social-media svg{
        font-size:rem(20);

        @include breakpoint-md{
            font-size:rem(22);
        }

        @include breakpoint-lg{
            font-size:rem(24);
        }
    }

    &__social-item a{
        display:inline-flex;
        align-items:center;
        padding:rem(10);
        border-radius:22px;
    }

    &__social-item a:hover{
        background-color:lighten($clr-primary, 10%);
    }

    &__social-item a:hover svg{
        transition:0.2s fill ease;
    }

    &__social-item a:hover svg{
        fill:$clr-secondary;
    }
}