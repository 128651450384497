@import '../Abstract/colors';
@import '../Abstract/function';
@import '../Abstract/breakpoints';
@import '../Abstract/mixins';

.header{
    position:fixed;
    width:100%;
    top:0;
    left:0;
    right:0;
    padding:rem(18) 0;
    transition:0.25s all ease-in-out;
    z-index:992;

    &__wrapper{
        @include flex-center-vertical;
        justify-content:space-between;
    }

    .navbar-toggle {
        margin-left:rem(48);
        @include flex-center;
        background-color:transparent;
        border-radius:50%;
        width:rem(40);
        height:rem(40);
        border: none;
        outline: none;
        transition:0.2s all ease-in-out; 

        &:hover{
            background:rgba($clr-secondary, 0.85);
            backdrop-filter:blur(5px);
            cursor:pointer;
        }
    }

    .navbar-toggle-block {
        width: rem(30);
        height: rem(20);
        position: relative;
    }

    .navbar-toggle-bar {
        position: absolute;
        right: 0;
        display: block;
        height: 3px;
        width: 100%;
        background-color: $clr-primary;
    }

    .navbar-toggle-bar:nth-of-type(1) {
        top: 0;
        width: 100%;
    }

    .navbar-toggle-bar:nth-of-type(2) {
        top: 50%;
        width: 85%;
        transform: translate3d(0, -50%, 0);
    }

    .navbar-toggle-bar:nth-of-type(3) {
        bottom: 0;
        width: 100%;
    }
}

// =========== sticky header =============
.header--sticky{
    background-color:rgba($clr-primary, 0.85);
    backdrop-filter:blur(4px);
    animation: moveDown 0.45s ease-in;

    .navbar-toggle-bar{
        background-color:$clr-white;
    }

    .nav__link{
        color:$clr-white;

        &:hover{
            color:$clr-secondary;
        }
    }

    .nav__link.active{
        color:$clr-secondary;
    }

    .header__logo--white{
        display:block;
    }

    .header__logo--black{
        display:none;
    }
}

.overlay{
    position:fixed;
    inset:0;
    width:100%;
    height:100vh;
    background-color:rgba($clr-primary, 0.65);
    backdrop-filter:blur(6px);
    z-index:1;
    display:none;


    &--active{
        display:block;
    }
}

@keyframes moveDown {
    from {
      transform: translateY(-100%);
      opacity:0;
    }
    to {
      transform: translateY(0);
      opacity:1;
    }
}