@import '../Abstract/function';
@import '../Abstract/font' ;
@import '../Abstract/colors';
@import '../Abstract/mixins';

// ===== base and responsive font size  ========

// em based breakpoint values for min-width operators.
$breakpoints: (
  sm : em(576),
  md: em(768),
  lg: em(992),
  xl: em(1200),
  xxl: em(1600),
);

// Null is mobile, no breakpoint.
$p-font-sizes: (
  null : (rem(16), rem(32)), // Font sizes and line height 
  sm : (rem(16), rem(32)),
  md : (rem(18), rem(36)),
  lg: (rem(18), rem(36)),
  xl: (rem(18), rem(36)),
  xxl:(rem(20), rem(40))
);

$h1-font-sizes: (
  null: (rem(48), rem(60)),   
  sm : (rem(56), rem(70)),
  md : (rem(64), rem(80)),
  lg: (rem(72), rem(84)),
  xl: (rem(72), rem(84)),
  xxl:(rem(74), rem(86))
);

$h2-font-sizes:(
  null:(rem(32), rem(38)), 
  sm :(rem(37), rem(44)),
  md :(rem(42), rem(48)),
  lg: (rem(48), rem(56)),
  xl: (rem(48), rem(56)),
  xxl: (rem(52), rem(68))
);

$h3-font-sizes: (
  null :(rem(28), rem(48)), 
  sm :(rem(28), rem(48)),
  md :(rem(30), rem(60)),
  lg:(rem(32), rem(64)),
  xl:(rem(32), rem(64)),
  xxl:(rem(34), rem(48)),
);

$h4-font-sizes: (
  null  :(rem(24), rem(44)), 
  sm :(rem(24), rem(44)),
  md :(rem(24), rem(44)),
  lg:(rem(24), rem(40)),
  xl:(rem(24), rem(40)),
  xxl:(rem(26), rem(44)),
);

$h5-font-sizes: (
  null :(rem(20), rem(36)),
  sm :(rem(20), rem(36)),
  md :(rem(20), rem(36)),
  lg:(rem(20), rem(36)),
  xl:(rem(20), rem(36)),
  xxl:(rem(22), rem(38))
);

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}


// ======== adding both font size and font-family mixins====
h1{
    @include font-size($h1-font-sizes);
}

h2{
    @include font-size($h2-font-sizes);
}

h3{
    @include font-size($h3-font-sizes);
   
}

h4{
    @include font-size($h4-font-sizes);
}

h5{
  @include font-size($h5-font-sizes);
}

h1, h2, h3, h5{
  @include font-heading;
}

p, ul, ol, body{
    @include font-size($p-font-sizes);
    @include font-body;
}

a{
  color:$clr-text;
}